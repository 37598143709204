import { StyleSheet} from 'react-native';

import Theme from '../theme/theme';


const background = Theme.background
const header = Theme.header
const header_text_color = Theme.header_text_color
const font = Theme.font
const row_background_color = Theme.row_background_color
const other_text_color = Theme.other_text_color
const native_label_color = Theme.native_label_color
const border_color = Theme.border_color
const labels = Theme.labels
const chart_line = Theme.chart_line
const chart_labels = Theme.chart_labels

const DarkTheme = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: background,
        alignItems: 'center',
        height:'100%',
        width:'100%',
        maxWidth:972,
        alignSelf:'center'
      },

      headerBackground: {
        backgroundColor: header,
        alignItems: 'center',
        justifyContent:'flex-end',
        height:100,
        width:'100%',
      },
    
      headerText: {
          fontFamily: font,
          color:other_text_color,
          fontSize:25,
          paddingBottom:5,
        },
    
    title: {
        fontFamily: font,
        color:other_text_color,
        fontSize:20,
        marginTop:150,
        marginBottom: 25,
    },

    rowSymbol: {
        fontFamily: font,
        paddingLeft:15,
        color: other_text_color,
        fontSize:15,
        fontWeight:'bold',
      },

      rowPrice: {
        fontFamily: font,
        color: other_text_color,
        fontSize:10,
      },

      rowValue: {
        fontFamily: font,
        color: other_text_color,
        fontSize:12,
      },

      rowContainer: {
        flexDirection:'row',
        alignItems:'center',
        backgroundColor: row_background_color,
        borderWidth: 2,
        borderRadius:10,
        borderColor:border_color,
        height: 65,
        marginVertical:3,
        marginHorizontal:12,
        paddingHorizontal:10,
      },

      arrow: {
        color:other_text_color,
        fontFamily: font,
        fontSize:15,
        justifyContent:'flex-end',
      },
    
      //Hidden Tokens Screen
      containerHiddenTokens: {
        flex: 1,
        backgroundColor: background,
        alignItems: 'center',
        justifyContent: 'center',
        height:'100%',
        width:'100%',
        maxWidth:972,
        alignSelf:'center'
      },
      
      headerBackgroundHiddenTokens: {
        backgroundColor: header,
        alignItems: 'center',
        justifyContent:'flex-end',
        height:90,
        width:'100%',
      },
    
      headerTextHiddenTokens: {
          fontFamily: font,
          color:other_text_color,
          fontSize:25,
          paddingBottom:5,
        },
    
  
      rowContainerHiddenTokens: {
        flexDirection:'row',
        backgroundColor: row_background_color,
        borderRadius:10,
        borderColor:border_color,
        borderWidth:2,
        height: 65,
        marginVertical:3,
        marginHorizontal:12,
        paddingHorizontal:10,
      },
    
      swipeStyle: {
        justifyContent:'center',
        alignItems:'center',
        marginVertical:3,
        marginLeft:-10,
        marginRight:12,
        width:'20%',
        height:65,
        backgroundColor: '#49C07D',
        borderRadius:10,
      },

      hideIcon: {
        fontFamily: font,
        fontSize:25,
        color:other_text_color
      },
    
      wallets: {
        fontFamily: font,
        paddingRight:0,
        color: other_text_color,
        fontSize:15,
        fontWeight:'bold',
     },

     walletName: {
        fontFamily: font,
        paddingRight:0,
        color: other_text_color,
        fontSize:12,
        fontWeight:'bold',
     },
     labels: {
        color:labels,
        fontFamily: font,
        fontSize:12,
      },

     addWalletContainer: {
        flexDirection:'row',
        justifyContent: 'center',
        alignItems:'center',
        backgroundColor:row_background_color,
        height:50,
        width:'100%'
      },

      addWalletSubContainer: {
        justifyContent:'center',
        alignItems:'center',
        height:'100%',
        width: '33%',
      },
      
      swipeLeftIcon: {
        fontSize:15,
          color:header
        
      },
      addIcon: {
        fontSize:20,
        color:'white',
      },
  
      walletsAdd: {
        fontFamily: font,
        marginLeft:245,
        paddingTop:10, 
        paddingRight:30,
        color: background,
        fontSize:15,
        fontWeight:'bold',    
      },

      imgStyle: {
        width: 40,
        height: 40,
        marginTop:5,
      },

  });

  export { DarkTheme };