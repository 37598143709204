import { StyleSheet} from 'react-native';

import Theme from '../theme/theme';


const background = Theme.background
const header = Theme.header
const header_text_color = Theme.header_text_color
const font = Theme.font
const row_background_color = Theme.row_background_color
const other_text_color = Theme.other_text_color
const native_label_color = Theme.native_label_color
const border_color = Theme.border_color
const labels = Theme.labels
const chart_line = Theme.chart_line
const chart_labels = Theme.chart_labels

const ChartColors = {
  background: background,
  gradientFrom: background,
  gradientTo: background,
  color: chart_line,
  labelColor: chart_labels
}

const MenuColors = {
background: background,
text: header_text_color,
border: border_color,
font: font
}


const DarkTheme = StyleSheet.create({
  containermain: {
      backgroundColor: background,
      alignSelf:'center',
      height:'100%',
      width:'100%',
      maxWidth:972
      
    },
    headerBackgroundmain: {
        
      backgroundColor: header,
      alignItems: 'center',
      justifyContent:'flex-end',
      height:100,
      width:'100%',},
  
    headerTextmain: {
        fontFamily: font,
        color:other_text_color,
        fontSize:25,
        paddingBottom:5,
      },
    nativeContainermain:{
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      width:'97%',
      backgroundColor:row_background_color,
      borderRadius:10,
      height:70,
      alignSelf:'center',
      marginTop:10,

    },
    nativeSubContainermain:{
      flexDirection:'row',
      justifyContent:'center',
      alignItems:'center',
      width:'33%',

    },
    nativeSymbolsmain: {
      color:other_text_color,
      fontWeight:'bold',
      marginHorizontal:0,
      paddingLeft:5,
      fontSize: 15,
      fontFamily:font,
      },
    nativeValuesmain: {
      color:header,
      marginTop:-2,
      marginHorizontal:0,
      paddingLeft:5,
      fontSize: 14,
      fontFamily:font,
    },
    namemain: {
      color:other_text_color,
      fontFamily: font,
      fontSize:17,
      paddingLeft:5,
    },
    nameMainLong: {
      color:other_text_color,
      fontFamily: font,
      fontSize:15,
      paddingLeft:5,
    },
    contractmain: {
      color:other_text_color,
      fontFamily: font,
      fontSize:12,
      marginTop:0,
    },
    percentageChangePositivemain: {
      color:'#699e71',
      fontFamily: font,
      fontSize:22,
      
      
    },
    
    percentageChangeNegativemain: {
      color:'#CF6679',
      fontFamily: font,
      fontSize:22,
      marginRight:10,
    },
    labelsmain: {
      color:'#9c9c9c',
      fontFamily: font,
      fontSize:12,
    },
    valuesmain: {
      
      color:other_text_color,
      fontFamily: font,
      fontSize:15,
    },
    decimalValuesmain: {
      paddingTop:10,
      color:other_text_color,
      fontFamily: font,
      fontSize:11,
    },
    dataContainermain: {
      width:'100%',
      flexDirection:'row',
      justifyContent:'space-evenly',
      paddingVertical:10,
      
      
      
    },
    dataColumnmain: {
      flexDirection:'column',
      alignItems:'center',
    },
    transactionsContainermain: {
      width:'98%',
      
      justifyContent:'space-evenly',
      alignItems:'center',
      paddingVertical:5,
      borderRadius:10,
      
    },
    transactionsHeadermain: {
      color:other_text_color,
      fontFamily: font,
      fontSize:20,
      
    },
    
    pricemain: {
      color:other_text_color,
      fontFamily: font,
      fontSize:13,
      
    },

    flatlistContainermain: {
      paddingHorizontal:10,
      marginTop:20,
      justifyContent:'center',
      width:'100%'

    },
    titlemain:{
      color:other_text_color,
      fontFamily: font,
      fontSize:17,
      paddingLeft:10,
      paddingBottom:5,
    },
    
    
    rowContainermain: {
      flexDirection:'column',
      justifyContent:'space-evenly',
      backgroundColor: row_background_color,
      borderWidth: 2,
      borderRadius:10,
      borderColor:border_color,
      height: 150,
      width:150,
      marginVertical:3,
      marginHorizontal:3,
      paddingHorizontal:10,
    },
  
    imgStylemain: {
      marginLeft:8,
      width: 40,
      height: 40,
    },
    chainImgStylemain: {
      marginLeft:8,
      width: 30,
      height: 30,
    },
    rangeContainermain: {
      flexDirection:'row',
      justifyContent:'space-evenly',
      marginTop:-20,
      marginBottom:10,
      width:'100%',
      
    },
    inactiveChartmain: {
      width:40,
      height:40,
      backgroundColor:border_color,
      borderRadius:5,
      alignItems:'center',
      justifyContent:'center'
    },
    activeChartmain: {
      width:40,
      height:40,
      backgroundColor:border_color,
      color:other_text_color,
      
      borderRadius:5,
      alignItems:'center',
      justifyContent:'center'
    },
    activeRangemain:{
      color:other_text_color,
      fontFamily: font,
      fontSize:15,
    },
    inactiveRangemain: {
      color:"#000",
      fontFamily: font,
      fontSize:15,
    },

    //Token Page
    container: {
        flex: 1,
        backgroundColor: background,
        alignItems: 'center',
        justifyContent: 'center',
        height:'100%',
        width:'100%',
        maxWidth:972,
        alignSelf:'center'
        
      },
      headerBackground: {
          
        backgroundColor: header,
        alignItems: 'center',
        justifyContent:'flex-end',
        height:90,
        width:'100%',},
    
      headerText: {
          fontFamily: font,
          color:other_text_color,
          fontSize:25,
          paddingBottom:5,
        },
      name: {
        color:other_text_color,
        fontFamily: font,
        fontSize:20,
      },
      contract: {
        color:other_text_color,
        fontFamily: font,
        fontSize:12,
        marginTop:0,
      },
      percentageChangePositive: {
        color:'#699e71',
        fontFamily: font,
        fontSize:22,
        
        
      },
      
      percentageChangeNegative: {
        color:'#CF6679',
        fontFamily: font,
        fontSize:22,
        marginRight:10,
      },
      labels: {
        color:header,
        fontFamily: font,
        fontSize:12,
      },
      values: {
        
        color:other_text_color,
        fontFamily: font,
        fontSize:15,
      },
      dataContainer: {
        width:'100%',
        flexDirection:'row',
        justifyContent:'space-evenly',
        paddingVertical:10,
        
        
        
      },
      dataColumn: {
        flexDirection:'column',
        alignItems:'center',
      },
      transactionsContainer: {
        width:'98%',
        
        justifyContent:'space-evenly',
        alignItems:'center',
        paddingVertical:5,
        borderRadius:10,
        
      },
      transactionsHeader: {
        color:other_text_color,
        fontFamily: font,
        fontSize:20,
        
      },
      
      price: {
        color:other_text_color,
        fontFamily: font,
        fontSize:13,
        marginTop:0,
      },
      
      
      rowContainer: {
        flexDirection:'row',
        backgroundColor: background,
        borderWidth: 2,
        borderRadius:10,
        borderColor:background,
        height: 65,
        marginVertical:3,
        marginHorizontal:12,
        paddingHorizontal:10,
      },
    
      imgStyle: {
        width: 45,
        height: 45,
      },
      rangeContainer: {
        flexDirection:'row',
        justifyContent:'space-evenly',
        marginTop:-20,
        marginBottom:10,
        width:'100%',
        
      },
      inactiveChart: {
        width:40,
        height:40,
        backgroundColor:border_color,
        borderRadius:5,
        alignItems:'center',
        justifyContent:'center'
      },
      activeChart: {
        width:40,
        height:40,
        backgroundColor:border_color,
        color:other_text_color,
        
        borderRadius:5,
        alignItems:'center',
        justifyContent:'center'
      },
      activeRange:{
        color:other_text_color,
        fontFamily: font,
        fontSize:15,
      },
      inactiveRange: {
        color:"#000",
        fontFamily: font,
        fontSize:15,
      },
      infoContainer: {
        width:'100%',
        flexDirection:'row',
        justifyContent:'center',
        paddingVertical:10,
      },
      infoColumn: {
          width:'33%',
          flexDirection:'column',
        alignItems:'center',
  
      },
      aboutContainer: {
          width:'95%',
          backgroundColor:row_background_color,
          borderRadius:10,
          marginHorizontal:10,
          marginTop:20,
          padding:17,
          borderWidth:2,
          borderColor:border_color
  
      },
      infoTitle: {
          color:other_text_color,
          fontFamily: font,
          fontSize:20,
          alignSelf:'center',
          paddingBottom:3
      },
      descriptionTitle: {
          color:other_text_color,
          fontFamily: font,
          fontSize:17,
      },
      description: {
        color:other_text_color,
        fontFamily: font,
        fontSize:13,
        marginVertical:7,
        
      },
      searchRowContainer: {
        alignSelf:'center',
        flexDirection:'row',
        justifyContent:'space-evenly',
        backgroundColor: row_background_color,
        borderWidth: 2,
        borderRadius:10,
        height: 65,
        width:'97%',
        marginVertical:3,
        marginHorizontal:3,
        paddingHorizontal:10,
        borderWidth:2,
        borderColor:border_color
      },
      searchSubContainers: {
        alignItems:'center',
        justifyContent:'center',
        width:'33%',
        flexDirection:'row'
      },
      searchLabelsContainer: {
        alignSelf:'center',
        flexDirection:'row',
        justifyContent:'space-around',
        borderRadius:10,
        borderColor:background,
        height: 20,
        width:'97%',
        marginVertical:3,
        marginHorizontal:3,
        paddingHorizontal:10
      },

      imgStyle: {
        marginLeft:8,
        width: 40,
        height: 40,
      },

      textInput: {
        width:'70%',
        paddingLeft:14,
        fontFamily: font,
        fontSize:15,
        },

      rowPercentageGain: {
        fontFamily: font,
        marginLeft:0,
        paddingTop:0,
        paddingRight:0,
        color: '#699e71',

        fontSize:17,

      },
      rowPercentageLoss: {
        fontFamily: font,
        marginLeft:0,
        paddingTop:0,
        paddingRight:0,
        color: '#CF6679',
        fontSize:17,

      },
  

  });

  export { DarkTheme };