import React, {useState,useEffect} from 'react';
import {TouchableOpacity,FlatList,StyleSheet,Text,View,Image,Linking} from 'react-native';
import {Swipeable,GestureHandlerRootView } from 'react-native-gesture-handler';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { useIsFocused } from "@react-navigation/native";
import Amplify, { Auth} from 'aws-amplify';
import * as Clipboard from 'expo-clipboard';
import { LightTheme } from './walletLightTheme';
import { DarkTheme } from './walletDarkTheme';
import Menu from '../components/menu';








const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }



function WalletsScreen({ navigation,route }) {

    const isFocused = useIsFocused();
    const [refreshing, setRefreshing] = useState(true);
    const [edit,setEdit]=useState(false);
    const [wallets,setWallets] = useState([]);
    const [banner,setBanner] = useState(0);
    const [bannerImages,setBannerImages] = useState(undefined);
    const [ads,setAds] = useState(undefined);

    async function load(){
      setRefreshing(true);
      const user = getUser().then((result)=> {

        //var Data = {"Command":"wallets","User":result};
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
        //var raw = JSON.stringify(Data);
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        const grab = fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets?email="+result, requestOptions)
        .then(response => response.json())
        .then((
          responseJson) => {console.log('Fetching Wallet Data..');
          var data = responseJson;
          console.log(data);
          setWallets(data);
          setRefreshing(false)});
        });
    }
    useEffect(() => {
        //getAds();
        load();
        //changeBanner();

        }, [isFocused])

        const changeBanner = async() => {
          while(1>0){
            await wait(7000);
            await setBanner(1);
            await wait(7000);
            await setBanner(2);
            await wait(7000);
            await setBanner(0);
          }
      }
      function wait(timeout) {
        return new Promise(resolve => {
            setTimeout(resolve, timeout);
        });
    }

    async function getAds(){
        
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/get-ads?page=wallets", requestOptions)
      var values = await grab.json();
      console.log(values);
      setAds(values);
      setBannerImages([{uri: values[0].image},{uri: values[1].image},{uri: values[2].image}])

    }

        function formatWallet(wallet) {
            if (wallet != null){
            wallet = wallet.slice(0,5)+'...'+wallet.slice(38);
            //console.log(wallet);

            return wallet;
            }
          }

      const Header = () => (
        <View style={DarkTheme.headerBackground}>
                <Text style={DarkTheme.headerText}>Your Wallets</Text>
            </View>
      )

      async function remove(item,wallet) {
        var user = await getUser();
        //console.log(user);

        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          redirect: 'follow'
        };
        fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/remove?email="+user+"&wallet="+wallet, requestOptions).then(response => response.json())
        .then((responseJson) => {


        });
        const fData = wallets.filter(item => item.wallet_address !== wallet);
        setWallets(fData);



      }


    return (
      <View style={DarkTheme.container}>
        <Header />
        <Menu props={{"navigation": navigation}}/>
        <View style={DarkTheme.addWalletContainer}>
          <View style={DarkTheme.addWalletSubContainer}>
            <Text style={DarkTheme.labels}>  Add or remove a wallet.</Text>

          </View>


          <View style={DarkTheme.addWalletSubContainer1}>
              <View style={{alignItems:'center',width:'5%',marginRight:"30%",}}>
                <TouchableOpacity style={{width:'100%'}} onPress={()=> {!edit ?setEdit(true):setEdit(false);}}>
                {edit ? <FontAwesome5 name={'times'} style={{fontSize:25,color:'#fff'}}/> : <FontAwesome5 name={'edit'} style={{fontSize:25,color:'#fff'}}/>}
                </TouchableOpacity>
              </View>
              <TouchableOpacity style={DarkTheme.addButton} onPress={() => navigation.navigate('Add Wallet')}>

                  <FontAwesome5 name={'plus'} style={DarkTheme.addIcon}/>
                  <Text style={DarkTheme.addButtonText}>  Add New</Text>


              </TouchableOpacity>

              

          </View>
        </View>






        <FlatList
        style={DarkTheme.walletBorder}
        data={wallets}
        onRefresh={() => {load();}}
        refreshing={refreshing}
        renderItem={({item}) => (
          <GestureHandlerRootView>

            <Swipeable renderRightActions={(progress,dragX) => {
              const trans = dragX.interpolate({
                inputRange: [-100, 0],
                outputRange: [1, 0],
                extrapolate: 'clamp',
              });

              async function remove(item,wallet) {
                var user = await getUser();
                //console.log(user);

                var myHeaders = new Headers();
                myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

                var requestOptions = {
                  method: 'DELETE',
                  headers: myHeaders,
                  redirect: 'follow'
                };
                fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/remove?email="+user+"&wallet="+wallet, requestOptions).then(response => response.json())
                .then((responseJson) => {


                });
                const fData = wallets.filter(item => item.wallet_address !== wallet);
                setWallets(fData);



              }

              return (
                <>

                <TouchableOpacity style={DarkTheme.swipeStyle} onPress={()=> {remove(item,item.wallet_address)}}>


                     <FontAwesome5 name={'trash-alt'} style={DarkTheme.hideIcon}/>


                 </TouchableOpacity>


                </>
              )
            }}>






          <View style={DarkTheme.rowContainer}>
            <View style = {{justifyContent:'center', alignItems:'center', width:'40%'}}>
                <Text style={DarkTheme.labels}>Nickname</Text>
                <Text style={DarkTheme.walletName}>{item.wallet_name}</Text>
            </View>
            <View style = {{justifyContent:'center', alignItems:'center',width:'40%'}}>
              <TouchableOpacity style={{justifyContent:'center', alignItems:'center',}}onPress={() => {Clipboard.setString(item.wallet_address)}}>
                <Text style={DarkTheme.labels}>Address</Text>
                <Text style={DarkTheme.wallets}>{formatWallet(item.wallet_address)} <FontAwesome5 name={'copy'} style={{fontSize:14}}/></Text>
              </TouchableOpacity>
            </View>
            {edit ? <><TouchableOpacity style={DarkTheme.swipeStyle} onPress={()=> {remove(item,item.wallet_address)}}>
                     <FontAwesome5 name={'trash'} style={DarkTheme.hideIcon}/>
            </TouchableOpacity></> : null}
          </View>

          </Swipeable>
            </GestureHandlerRootView>
          ) }
        extraData={wallets}
        keyExtractor={(item)=> item._id}
        />
        {bannerImages ?
        <View style={{marginBottom:20,height:60,width:'100%'}}>
                <TouchableOpacity onPress={() =>Linking.openURL(ads.banner[banner].url)}>
                <Image
                    source={bannerImages[banner]}
                    style={{width: "100%",
                      height: 80,
                      }}
                    resizeMode={'contain'}
                    ></Image>
                </TouchableOpacity>
        </View>
      : null }
      </View>

    );
  }

  export default WalletsScreen;
