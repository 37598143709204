import { StyleSheet} from 'react-native';

import Theme from '../theme/theme';


const background = Theme.background
const header = Theme.header
const header_text_color = Theme.header_text_color
const font = Theme.font
const row_background_color = Theme.row_background_color
const other_text_color = Theme.other_text_color
const native_label_color = Theme.native_label_color
const border_color = Theme.border_color
const labels = Theme.labels
const chart_line = Theme.chart_line
const chart_labels = Theme.chart_labels

const ChartColors = {
    background: background,
    gradientFrom: background,
    gradientTo: background,
    color: chart_line,
    labelColor: chart_labels
}

const MenuColors = {
  background: background,
  row_background_color: row_background_color,
  text: header_text_color,
  border: border_color,
  font: font
}

const DarkTheme = StyleSheet.create({
    container: {
      flex:2,
      backgroundColor: background,
      alignItems: 'center',
      height:'100%',
      width:'100%',
      maxWidth:972,
      alignSelf:'center'
      
      
    },
    headerBackground: {
      backgroundColor: header,
      alignItems: 'center',
      justifyContent:'flex-end',
      height:90,
      width:'100%',

    },

    portfolioHeaderBackground: {
      flexDirection:'row',
      backgroundColor: header,
      alignItems: 'flex-end',
      height:90,
      paddingHorizontal:20,
      width:'100%',
      justifyContent:'space-between',
      borderRadius:10,
      
    },
  
    headerText: {
        fontFamily: font,
        color:header_text_color,
        fontSize:25,
        paddingBottom:5,
      },
      
    portfolio: {
        backgroundColor: row_background_color,
        width: '95%',
        borderRadius: 10,
        marginBottom:15,
        justifyContent:'center',
        alignItems:'center',
        marginTop:15,
        borderWidth:2,
        borderColor:border_color

    },
    wallets: {
      fontFamily: font,
        paddingRight:0,
        color: other_text_color,
        fontSize:15,
        fontWeight:'bold',
     },
     walletName: {
      fontFamily: font,
      paddingRight:0,
      color: other_text_color,
      fontSize:15,
      fontWeight:'bold',
   },
    portfolioTotal: {
        color:other_text_color,
        marginTop:5,
        marginHorizontal:15,
        fontSize: 35,
        fontFamily:font,

    },
    portfolioText: {
        color:other_text_color,
        marginTop:15,
        marginHorizontal:15,
        paddingLeft:0,
        fontSize: 15,
        fontFamily:font,

    },
    voteContainer: {
      width:'90%',
      alignSelf:'center',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:row_background_color,
      borderRadius:10,


    },
    dropdownContainer: {
      width:'100%',
      alignSelf:'center',
      flexDirection:'column',
      justifyContent:'center',
      alignItems:'center',
      backgroundColor:row_background_color,
      borderBottomEndRadius:10,
      borderBottomStartRadius:10
    },
    dropdownDataContainer: {
      width:'100%',
      flexDirection:'row',
      paddingVertical:7,
      paddingRight:35,
      backgroundColor:row_background_color,
      justifyContent:'flex-end',
      borderBottomColor: border_color,
      borderBottomWidth:1
    },
    contract: {
      color:header,
      fontFamily: font,
      fontSize:12,
      marginTop:0,
    },
    transactionsContainer: {
      width:'98%',
      justifyContent:'space-evenly',
      alignItems:'center',
      paddingVertical:15,
      borderRadius:10,
      
    },
    transactionsHeader: {
      color:other_text_color,
      fontFamily: font,
      fontSize:20,
      
    },
    nativeSymbols: {
      color:native_label_color,
      fontWeight:'bold',
      marginHorizontal:0,
      paddingLeft:5,
      fontSize: 15,
      fontFamily:font,
      },
    nativeValues: {
      color:other_text_color,
      marginTop:-2,
      marginHorizontal:0,
      paddingLeft:5,
      fontSize: 14,
      fontFamily:font,
    },
    nativeValuesB: {
      color:other_text_color,
      marginTop:-2,
      marginHorizontal:0,
      paddingLeft:5,
      fontSize: 14,
      fontFamily:font,
    },
    nativeSymbolContainer: {
      flexDirection:'column',
      width:70,
      height:75,
      marginTop:5,
      
    },
    nativeImageContainer: {
      flexDirection:'row',
      
      height:75,
    },
    rowSymbol: {
      fontFamily: font,
      paddingRight:0,
      color: other_text_color,
      fontSize:15,
      fontWeight:'bold',
    },
    rowPrice: {
      fontFamily: font,
      color: '#afb4cf',
      fontSize:10,
    },
    rowValue: {
      fontFamily: font,
      color: other_text_color,
      fontSize:12,
    },
    rowContainer: {
      flexDirection:'row',
      backgroundColor: row_background_color,
      borderWidth: 2,
      borderRadius:10,
      borderColor:border_color,
      height: 65,
      marginVertical:3,
      marginHorizontal:12,
      paddingHorizontal:10,
      width:'95%',
      alignSelf:'center'
    },
    walletDropdownRowContainer: {
      flexDirection:'row',
      backgroundColor: row_background_color,
      borderWidth: 2,
      borderRadius:10,
      borderColor:border_color,
      height: 60,
      marginVertical:3,
      marginHorizontal:27,
      paddingHorizontal:10,
    },

    walletBalanceRowContainer: {
      flexDirection:'row',
      justifyContent:'space-evenly',
      backgroundColor: row_background_color,
      borderRadius:10,
      borderColor:border_color,
      height: 50,
      marginVertical:3,
      paddingVertical:10,
    },

    imgStyle: {
      width: 40,
      height: 40,
      marginTop:5,
      
    },
    rowPercentageGain: {
      fontFamily: font,
      marginLeft:0,
      paddingTop:0,
      
      paddingRight:0,
      color: '#699e71',
      
      fontSize:12,
      fontWeight:'bold',
  
    },
    rowPercentageLoss: {
      fontFamily: font,
      marginLeft:0,
      paddingTop:0,
      
      paddingRight:0,
      color: '#CF6679',
      
      fontSize:12,
      fontWeight:'bold',
  
    },
    swipeStyle: {
      justifyContent:'center',
      alignItems:'center',
      marginVertical:5,
      marginLeft:-10,
      marginRight:12,
      width:'5%',
      height:63,
      backgroundColor: row_background_color,
      borderRadius:10,
    },

    hideIcon: {
      fontFamily: font,
      fontSize:20,
      color:other_text_color
    },

    modalBackground: {
      width:'100%',
      height:'100%',
      backgroundColor:'rgba(255,255,255,0.5)',
      justifyContent:'center',
      alignItems:'center'
    },
    modalContainer: {
      width:'95%',
      height:420,
      backgroundColor:'#000',
      borderRadius:10,
      justifyContent:'center'
    },
    modalHeader: {
      color:other_text_color,
      alignSelf:'center',
      fontFamily:font,
      fontSize:20,
      marginTop:15,
    },

    modalRow: {
      flexDirection:'row',
      width:'95%',
      height:65,
      backgroundColor:row_background_color,
      alignSelf:'center',
      marginVertical:3,
      marginRight:5,
      borderRadius:10,
      paddingHorizontal:10,
    },
    modalRowContainerSelected: {
      flexDirection:'row',
      width:'95%',
      backgroundColor: row_background_color,
      borderColor: border_color,
      borderWidth:2,
      borderRadius:10,
      height: 65,
      alignSelf:'center',
      marginVertical:3,
      marginRight:5,
      borderRadius:10,
      paddingHorizontal:10,
    },
    modalRowSection: {
      width:'40%',
      alignItems:'center',
      justifyContent:'center'
    },
    modalRowLabels:{
      color:labels,
      fontSize:12,
      fontFamily:font,
    },

    modalRowName: {
      color:other_text_color,
      fontSize:13,
      fontFamily:font,
    },

    modalIgnoreButton: {
      backgroundColor:'red',
      paddingHorizontal:20,
      paddingVertical:3,
      marginVertical:5,
      borderRadius:5
    },

    modalIncludeButton: {
      backgroundColor:'#699e71',
      paddingHorizontal:20,
      paddingVertical:3,
      marginVertical:5,
      borderRadius:5
    },
    modalButtonText: {
      color:other_text_color,
      fontSize:15,
      fontFamily:font,
    },

    modalCloseContainer: {
      width:'100%',
      alignItems:'center',
      paddingVertical:5,
    },

    modalCloseButton: {
      flexDirection:'row',
      backgroundColor:header,
      paddingVertical:5,
      marginVertical:5,
      paddingHorizontal:70,
      borderRadius:10
    },
    modalCloseButtonText: {
      color:'white',
      fontFamily:font,
      fontSize:20
    },

    selectionText: {
      fontFamily: font,
      color:'white',
      fontSize:13,  
    },

  swipeStyle: {
    justifyContent:'center',
    alignItems:'center',
    marginLeft:'-5%',
    marginRight:12,
    width:'5%',
    height:50,
    marginTop:7,
    backgroundColor: row_background_color,
    borderRadius:10,
  },

  swipeLeftIcon: {
    fontSize:15,
    color:'#9c9c9c'
  },

  swipeStyleAdd: {
    justifyContent:'center',
    alignItems:'center',
    marginVertical:3,
    marginLeft:-10,
    marginRight:12,
    width:'20%',
    height:65,
    backgroundColor: '#699e71',
    borderRadius:10,
  },

  //Token Details
  name: {
    color:'white',
    fontFamily: font,
    fontSize:20,
  },

  percentageChangePositive: {
    color:'#699e71',
    fontFamily: font,
    fontSize:22,  
  },

  percentageChangeNegative: {
    color:'#CF6679',
    fontFamily: font,
    fontSize:22,
    marginRight:10,
  },
  labels: {
    color:labels,
    fontFamily: font,
    fontSize:12,
  },

  values: {
    color:other_text_color,
    fontFamily: font,
    fontSize:15,
  },
  valuesPositive: {
    color:'#49C07D',
    fontFamily: font,
    fontSize:15,
  },

  valuesNegative: {
    color:'#CF6679',
    fontFamily: font,
    fontSize:15,
  },

  dataContainer: {
    width:'100%',
    flexDirection:'row',
    justifyContent:'space-evenly',
    paddingVertical:10,
    
  },

  communityContainer: {
    width:'100%',
    flexDirection:'row',
    justifyContent:'space-evenly',
    paddingVertical:10,
    borderWidth:2,
    borderColor:border_color,
    borderRadius:10,
    marginVertical:5
  },

  dataColumn: {
    flexDirection:'column',
    alignItems:'center',
  },

  price: {
    color:'#afb4cf',
    fontFamily: font,
    fontSize:15,
    marginTop:0,
  },
  
  rowContainerTokens: {
    flexDirection:'row',
    backgroundColor: row_background_color,
    borderBottomWidth: 2,
    borderColor:border_color,
    height: 80,
  },

  imgStyle: {
    width: 30,
    height: 30,
  },
  rangeContainer: {
    flexDirection:'row',
    justifyContent:'space-evenly',
    marginTop:0,
    marginBottom:0,
    width:'100%',
    
  },
  inactiveChart: {
    width:40,
    height:40,
    backgroundColor:border_color,
    borderRadius:5,
    borderWidth:2,
    borderColor:border_color,
    alignItems:'center',
    justifyContent:'center'
  },
  rangeContainer2: {
    flexDirection:'row',
    justifyContent:'space-between',
    marginTop:0,
    marginBottom:0,
    width:'100%',
    
  },

  inactiveNotifications: {
    width:70,
    height:50,
    backgroundColor:header,
    borderRadius:5,
    alignItems:'center',
    justifyContent:'center'
  },

  activeNotifications: {
    width:70,
    height:50,
    backgroundColor:header,
    color:header,
    borderRadius:5,
    borderColor:border_color,
    borderWidth:2,
    alignItems:'center',
    justifyContent:'center'
  },
  

  activeRange2:{
    color:other_text_color,
    fontFamily:font,
    fontSize:20,
  },

  inactiveRange2: {
    color:border_color,
    fontFamily: font,
    fontSize:20,
  },
  activeChart: {
    width:40,
    height:40,
    backgroundColor:border_color,
    color:header,
    borderRadius:5,
    borderWidth:2,
    borderColor:border_color,
    alignItems:'center',
    justifyContent:'center'
  },

  activeRange:{
    color:other_text_color,
    fontFamily: font,
    fontSize:15,
  },

  inactiveRange: {
    color:'#000',
    fontFamily: font,
    fontSize:15,
  },

  
  transactionsDataColumn: {
    flexDirection:'column',
    alignItems:'center',
    width:'33%',
  },
  transactionsDataContainer: {
    backgroundColor:row_background_color,
    width:'95%',
    
    flexDirection:'column',
    alignItems:'center',
    paddingVertical:10,
    borderRadius:10,
    borderWidth:2,
    borderColor:border_color
  },
  transactionLabels: {
    color:labels,
    fontFamily: font,
    fontSize:17,
  },
  transactionValues: {
    color:other_text_color,
    fontFamily: font,
    fontSize:17,
  },
  transactionDetails: {
    color:other_text_color,
    fontFamily: font,
    fontSize:25,
  },
  invalid: {
    marginTop:-15,
    marginBottom:10,
    paddingLeft:0,
    color:'#CF6679',
    fontFamily: font,
    fontSize:15,
    alignSelf:'center'
    },


    // add token

    containeraddWallet: {
      flex: 1,
      backgroundColor: '#121212',
      alignItems: 'center',
         
     },
     
    headerBackgroundaddWallet: {
          
      backgroundColor: header,
      alignItems: 'center',
      justifyContent:'flex-end',
      height:90,
      width:'100%',},
    
   headerTextaddWallet: {
      fontFamily: font,
      color:other_text_color,
      fontSize:25,
      paddingBottom:5,
     },

    form: {
        alignItems:'center',
        padding:10,
        marginTop:15,
        justifyContent:'center'
    

    },
    t: {
        fontFamily: font,
        color:other_text_color,
        fontSize:12,
        marginBottom: 15,
        paddingHorizontal:12,
    },
    button: {
        flexDirection: "row",
        justifyContent: "space-evenly",
        marginTop:15,
        marginBottom:105,
        alignItems: "center",
        backgroundColor: labels,
        
        color:other_text_color,
        width: 200,
        height: 50,
        fontFamily: font,
        alignSelf:'center',
        borderRadius:10,
    },
    title: {
        fontFamily: font,
        color:other_text_color,
        fontSize:20,
        marginBottom: 25,
    },
    label: {
        fontFamily: font,
        color:labels,
        fontSize:15,
        marginBottom: 10,
    },

    textInput: {
        width:300,
        height:50,
        paddingHorizontal:15,
        marginBottom:25,
        backgroundColor:other_text_color,
        fontFamily:font,
        fontSize:15,
        borderRadius:10,
        },
    textInputTransactions: {
      width:"70%",
      height:40,
      paddingHorizontal:10,
      backgroundColor:other_text_color,
      fontFamily: font,
      fontSize:15,
      borderRadius:7,
      },

    
    

    buttonText: {
        fontFamily: font,
        color:other_text_color,
        fontSize:15,
        
    },
    infoContainer: {
      width:'100%',
      flexDirection:'row',
      justifyContent:'center',
      paddingVertical:10,
    },
    infoData: {
      justifyContent:'space-around',
      borderBottomColor:border_color,
      borderBottomWidth:2
    },
    infoColumn: {
        width:'33%',
        flexDirection:'column',
      alignItems:'center',

    },
    aboutContainer: {
        width:'95%',
        backgroundColor:row_background_color,
        borderRadius:10,
        borderWidth:2,
        borderColor:border_color,
        marginHorizontal:10,
        padding:17,

    },
    infoTitle: {
        color:other_text_color,
        fontFamily: font,
        fontSize:20,
        alignSelf:'center',
        paddingBottom:3
    },
    descriptionTitle: {
        color:header,
        fontFamily: font,
        fontSize:17,
    },
    description: {
      color:other_text_color,
      fontFamily: font,
      fontSize:13,
      marginVertical:7,
      
    },
    pContainerLarge: {
      width:'80%',
      flexDirection:'column',
      paddingLeft:"3%"

    },
    pContainerSmall:{
      width:'70%',
      flexDirection:'column',
      paddingLeft:"3%"
    },
    iContainerLarge:{
      alignItems:'center',
      width:'7%'
    },
    iContainerSmall: {
      alignItems:'center',
      width:'10%'
    }



  });

  const AddTokenStyles = StyleSheet.create({
    container: {
        flex:2,
        backgroundColor: background,
        alignItems: 'center',
        height:'100%'
        
      },
      headerBackground: {
        backgroundColor: header,
        alignItems: 'center',
        justifyContent:'flex-end',
        height:90,
        width:'100%',},
    
    headerText: {
        fontFamily: font,
        color:header_text_color,
        fontSize:25,
        paddingBottom:5,
    },
    rowContainer: {
        flexDirection:'row',
        alignItems:'center',
        backgroundColor: row_background_color,
        borderWidth: 2,
        borderRadius:10,
        borderColor:border_color,
        height: 50,
        marginVertical:3,
        marginHorizontal:12,
        paddingHorizontal:10,
        },
    rowSymbol: {
        fontFamily: font,
        
        color: other_text_color,
        fontSize:20,
        fontWeight:'bold',
    },
    

    textInput: {
        color:"#000",
        fontFamily: font,
        fontSize:15,
        
        },
    textContainer: {
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        width:'50%',
        height:50,
        backgroundColor:"#FFF",
        borderRadius:10
        },
    walletTextContainer: {
        flexDirection:'row',
        justifyContent:'center',
        alignItems:'center',
        width:300,
        height:50,
        backgroundColor:other_text_color,
        borderRadius:10
        },
    labels: {
        color:labels,
        fontFamily: font,
        fontSize:20,
        },
    saveButton: {
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor: labels,
        width:150,
        height:50,
        borderRadius:10,
        marginVertical:20
    },
    saveButtonDisabled: {
        flexDirection:'row',
        alignItems:'center',
        justifyContent:'center',
        backgroundColor: 'rgba(80, 87, 133,0.3)',
        width:150,
        height:50,
        borderRadius:10,
        marginVertical:20
    },
    buttonText: {
        fontFamily: font,
        color: other_text_color,
        fontSize:20,
        fontWeight:'bold',
    },
    buttonDisabled: {
        fontFamily: font,
        color: 'rgba(128,128,128,1)',
        fontSize:20,
        fontWeight:'bold',
    },
    

  });

  export { DarkTheme, ChartColors, MenuColors, AddTokenStyles };