import { withAuthenticator } from 'aws-amplify-react-native';
import React, {useState,useEffect} from 'react';
import { TouchableOpacity,FlatList,Text, View, Linking,Image } from 'react-native';
import Amplify, { Auth, withSSRContext } from 'aws-amplify';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { DarkTheme } from './settingsDarkTheme';
import { LightTheme } from './settingsLightTheme';
import Menu from '../components/menu';



const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }

  async function signOut() {
    try {
        await Auth.signOut();
    } catch (error) {
        console.log('error signing out: ', error);
    }
}  


function SettingsScreen({ navigation,route }) {
    
    
    const [options,setOptions] = useState([{'option':'Hidden Tokens'},{'option':'Promote Your Project'},{'option':'List Your Project'},{'option':'Privacy Policy'},{'option':'Logout'}]);

    const Header = () => (
      <View style={DarkTheme.headerBackground}>
              <Image 
                      source={require( './../img/gradient-horizontal.png')}
                      style={{width: 170,
                        height: 65,
                        
                      }}
                    ></Image>
          </View>
    )


    return (
        <View style={DarkTheme.container}>
          <Header />
          <Menu props={{"navigation": navigation}}/>
        <FlatList
        style={{width: '100%',marginTop:10}}
        data={options}
        renderItem={({item}) => (
            
        <TouchableOpacity 
        disabled={(item.option == 'Theme' || item.option == 'Update Email Address') ? true : false}

        onPress={() => {(item.option=='Logout') ? signOut() : (item.option=='Promote Your Project') ? Linking.openURL("https://lynxcrypto.app/advertise/") : (item.option=='List Your Project') ? Linking.openURL("https://lynxcrypto.app/list/") : (item.option == 'Privacy Policy') ? Linking.openURL('https://lynxcrypto.app/privacy-policy/') : navigation.navigate(item.option,{item: item})}}>    
          <View style={DarkTheme.rowContainer}>
              <View style={{width:'90%'}}>
                <Text style={DarkTheme.rowSymbol}>{item.option}</Text>
              </View>
              <View style={{width:'10%'}}>
                <FontAwesome5 name={'angle-right'} style={DarkTheme.arrow}/>
              </View>
              
              

          </View>
        </TouchableOpacity> 
          

          ) }
        extraData={options}
        keyExtractor={(item)=> item.option}
        />
            
            

        
        </View>
  
    )
  }

export default SettingsScreen;

