const Theme = {
        background: "#121212",
        header: "#121212",
        header_text_color: "#FFF",
        font: "Poppins_500Medium",
        row_background_color: "rgba(31,27,36,0.75)",
        other_text_color: "#FFF",
        native_label_color: "rgba(214,179,61, .75)",
        border_color: "#505785",
        labels:"rgba(214,179,61, 1)",
        chart_line: `#505785`,
        chart_labels: `rgba(255, 255, 255, 1)`,
}

export default Theme;