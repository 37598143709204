import React, {useState,useEffect} from 'react';
import { ScrollView,Keyboard,Image,Linking,ActivityIndicator,TouchableOpacity,FlatList,DarkThemeheet,Text,TextInput, View, ImageBackground } from 'react-native';
import { Auth } from 'aws-amplify';
import { DarkTheme } from './marketsDarkTheme';
import AsyncStorage from '@react-native-async-storage/async-storage';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import MarketsContentLoader from './marketsContentLoader';
import { useFonts, Poppins_500Medium} from '@expo-google-fonts/poppins'

import Menu from '../components/menu';



const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }


function Markets({ navigation,route }) {

    const initialSearch = []

    const [marketData,setMarketData]=useState(undefined);
    const [searching,setSearching]=useState(undefined);
    const [searchResults,setSearchResults]=useState(initialSearch);
    const [keyword,setKeyword] = useState(undefined);
    const [loadingSearch,setLoadingSearch] = useState(false);
    const [ads,setAds] = useState(undefined);
    const [banner,setBanner] = useState(0);
    const [bannerImages,setBannerImages] = useState(undefined);
    const [currency,setCurrency] = useState({
      "currency_code": "USD",
      "currency_prefix": ".",
      "currency_rate": "1.0",
    });



    useEffect(() => {
        //getAds();
        setData();
        loadMarketData();
        changeBanner();



      }, [navigation])

      const getData = async () => {
        try {
          const jsonValue = await AsyncStorage.getItem('@currency');
          return jsonValue != null ? JSON.parse(jsonValue) : {
        "currency_code": "USD",
        "currency_prefix": "$",
        "currency_rate": "1.00",
      };
        } catch(e) {
          // error reading value
        }
      }

      const setData = async () => {
        var data = await getData();
        setCurrency(data);
    }

    const changeBanner = async() => {
        while(1>0){
          await wait(7000);
          await setBanner(1);
          await wait(7000);
          await setBanner(2);
          await wait(7000);
          await setBanner(0);
        }
    }
    function wait(timeout) {
      return new Promise(resolve => {
          setTimeout(resolve, timeout);
      });
  }
    const Header = () => (
          <View style={DarkTheme.headerBackgroundmain}>
              <Image
                    source={require( './../img/gradient-horizontal.png')}
                    style={{width: 170,
                      height: 65,


                    }}
                    resizeMode={'contain'}
                  ></Image>

                  <TouchableOpacity onPress={() => setSearching(true)} style={{position:'absolute',color:'#fff',paddingLeft:310,paddingBottom:17}}>
                            <FontAwesome5 name={'search'} style={{fontSize:25,color:'#fff'}}/>
                  </TouchableOpacity>



          </View>


      )



    async function loadMarketData(){
        var email = await getUser();
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/listed-tokens?page=discover", requestOptions)
        var values = await grab.json();
        //console.log(values);
        await setMarketData(values);
        //console.log(marketData);

      }

    async function search(word){
        setLoadingSearch(true);
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");


        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        console.log(word)
        var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/search?searchString="+word, requestOptions)
        var values = await grab.json();
        console.log(values);
        setSearchResults(values);
        setLoadingSearch(false);

      }

      async function getAds(){
        
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/get-ads?page=discover", requestOptions)
        var values = await grab.json();
        console.log(values);
        setSearchResults(values.search)
        setBannerImages([{uri: values.banner[0].image},{uri: values.banner[1].image},{uri: values.banner[2].image}])
        //console.log(values.banner);
        setAds(values);
        changeBanner();




      }

      const SearchData = () => (
        <>

        <View style={{paddingTop:10,height:'70%'}}>
          <View style={DarkTheme.searchLabelsContainer}>
            <View style={DarkTheme.searchSubContainers}>
              <Text style={DarkTheme.labels}>Symbol</Text>
            </View>
            <View style={DarkTheme.searchSubContainers}>
              <Text style={DarkTheme.labels}>Chain</Text>
            </View>
            <View style={DarkTheme.searchSubContainers}>
              <Text style={DarkTheme.labels}>Change</Text>
            </View>
          </View>

        <FlatList
        style={{width: '100%',alignSelf:'center'}}
        data={searchResults}
        extraData={searchResults}
        renderItem={({item}) => (

        <TouchableOpacity

        onPress={() => navigation.navigate('Token Page',{item: item,currency: currency})}>
        <View style={DarkTheme.searchRowContainer}>
            <View style={DarkTheme.searchSubContainers}>

            <Image
                    source={{uri: item.project_info.logo_url}}
                    style={{width: 40,
                      height: 40,}}
                    resizeMode={'contain'}
                    ></Image>
                <Text style={DarkTheme.name}>{item.symbol.toUpperCase()}</Text>

            </View>
            <View style={DarkTheme.searchSubContainers}>
            <Image
                source={(item.chain == 56) ? Images.bsc : (item.chain == 1) ? Images.eth : Images.poly}
                style={{width: 35,
                  height: 35,}}
                ></Image>
            </View>
            <View style={DarkTheme.searchSubContainers}>
            <Text style={[(item.price_change > 0.00) ? DarkTheme.rowPercentageGain : DarkTheme.rowPercentageLoss]}> {parseFloat(item.price_change).toFixed(2)}%</Text>
            </View>




        </View>
        </TouchableOpacity>



        ) }

        keyExtractor={(item)=> item._id}
        />
        </View>
        </>
    )

    const decimalCheck = (num) => {
      var zeros = 0;
      var num = parseFloat(num)
      if (num < 0.01){
      while(num < 1){
        zeros++;
        num *= 10;

      }
      return zeros-1

    } else {
      return 0
    }

      //console.log("zeros is :"+zeros);
      //setDecimalCount(zeros+2);
    }

    const priceFormat = (num,zeros) => {

      num = num.toFixed(18)
      num = num.toString().slice(zeros)
      num = num.slice(0,5)
      return num

      //console.log("zeros is :"+zeros);
      //setDecimalCount(zeros+2);
    }

      const Images = {
      "bsc":require( './../img/logos/bnb.png'),
      "eth":require( './../img/logos/eth.png'),
      "poly":require( './../img/logos/poly.png')

    }




    return (
        <View style={DarkTheme.containermain}>

                <View style={DarkTheme.headerBackgroundmain}>
                    <Image
                          source={require( './../img/gradient-horizontal.png')}
                          style={{width: 170,
                            height: 65,


                          }}
                          resizeMode={'contain'}
                        ></Image>

                        <TouchableOpacity onPress={() => {searching ? setSearching(false) : setSearching(true)}} style={{position:'absolute',color:'#fff',paddingLeft:310,paddingBottom:17}}>
                                  <FontAwesome5 name={'search'} style={{fontSize:25,color:'#fff'}}/>
                        </TouchableOpacity>



                </View>
                <Menu props={{"navigation": navigation}}/>


            {searching ?



            <View style={{flexDirection:'row',justifyContent:'center',alignItems:'center',width:'100%',marginVertical:10,}}>


                <TouchableOpacity style={{paddingRight:20,marginLeft:-20,}} onPress={() => {setSearching(false);setKeyword('');Keyboard.dismiss();}}>
                    {(searching)?<FontAwesome5 name={'angle-double-left'} style={{fontSize:25,color:'#fff'}}/> :null}

                </TouchableOpacity>

                <View style={{flexDirection:'row',alignSelf:'center',width:'70%',height:40,borderRadius:10,marginTop:10,justifyContent:'center',alignItems:'center',backgroundColor:'#ffff'}}>
                    {(loadingSearch)?<ActivityIndicator size='small' color={(opacity = 2) => `rgba(100, 255, 255, ${opacity})`} /> : <FontAwesome5 name={'search'} style={{fontSize:15}}/> }
                    <TextInput
                            style={DarkTheme.textInput}


                            placeholder="Search..."
                            value={keyword}
                            onChangeText={word =>{setKeyword(word);search(word)}}
                            />
                            {(searching) ?
                    <TouchableOpacity onPress={()=>{setKeyword('');}}>
                        <FontAwesome5 name={'times'} style={{fontSize:17}}/>


                    </TouchableOpacity>
                    : null }

                </View>

              </View> : null}
            {(searching) ? <SearchData /> :
            <ScrollView keyboardDismissMode='on-drag' contentContainerStyle={{paddingBottom: 20}} style={{width:'100%'}}>

                <View style={DarkTheme.flatlistContainermain}>


                  {ads ?
                  <View style={{marginBottom:20,height:80,width:'100%'}}>
                <TouchableOpacity onPress={() =>Linking.openURL(ads.banner[banner].url)}>
                <Image
                    source={bannerImages[banner]}
                    style={{width: "100%",
                      height: 80,
                      }}
                    resizeMode={'contain'}
                    ></Image>
                </TouchableOpacity>
                </View>

              :null}


                <View style={{flexDirection:'row',alignItems:'center'}}>
                  <Image
                      source={require( './../../assets/icons/check.png')}
                      style={{width: 40,
                        height: 40,
                        marginBottom:5
                      }}
                    ></Image>
                  <Text style={DarkTheme.titlemain}>Community Picks</Text>
                </View>

                {(marketData)?
                <FlatList
                        style={{width: '100%'}}
                        data={marketData.communityPicks}
                        extraData={marketData.communityPicks}
                        horizontal={true}
                        showsHorizontalScrollIndicator={false}
                        renderItem={({item}) => (

                        <TouchableOpacity

                        onPress={() => navigation.navigate('Token Page',{item: item,currency: currency})}>
                        <View style={DarkTheme.rowContainermain}>
                            <View style={{ flexDirection:'row',alignItems:'center',width:'100%'}}>

                            <Image
                                    source={{uri: item.project_info.logo_url}}
                                    style={DarkTheme.imgStylemain}
                                    resizeMode={'contain'}
                                    ></Image>
                                <Text style={((item.symbol).length > 6) ? DarkTheme.nameMainLong : DarkTheme.namemain}>{item.symbol.toUpperCase()}</Text>
                            </View>
                            <View style={{width:'100%',alignItems:'center',justifyContent:'center',flexDirection:'row'}}>
                                <Text style={DarkTheme.valuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? currency.currency_prefix + "0.0" : null}</Text>
                                <Text style={DarkTheme.decimalValuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? decimalCheck(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) : null}</Text>
                                <Text style={DarkTheme.valuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? priceFormat(parseFloat(item.usd_price)*parseFloat(currency.currency_rate),decimalCheck(parseFloat(item.usd_price)*parseFloat(currency.currency_rate))+2): currency.currency_prefix+(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)).toFixed(5)} </Text>
                            </View>
                            <View style={{flexDirection:'row',justifyContent:"space-evenly",width:'100%',alignItems:'center'}}>

                                <Text style={[(item.price_change > 0.00) ? DarkTheme.rowPercentageGain : DarkTheme.rowPercentageLoss]}>{parseFloat(item.price_change).toFixed(4)}%</Text>
                                <Image
                                source={(item.chain == 56) ? Images.bsc : (item.chain == 1) ? Images.eth : Images.poly}
                                style={DarkTheme.chainImgStylemain}
                                ></Image>
                            </View>




                        </View>
                        </TouchableOpacity>


                        ) }

                        keyExtractor={(item)=> item.token_id}
                        />
                        : <MarketsContentLoader />}

               </View>

               <View style={DarkTheme.flatlistContainermain}>
               <View style={{flexDirection:'row',alignItems:'center'}}>
                  <Image
                      source={require( './../../assets/icons/fire.png')}
                      style={{width: 40,
                        height: 40,
                        marginBottom:8
                      }}
                    ></Image>
                  <Text style={DarkTheme.titlemain}>New Listings</Text>
                </View>

                   {(marketData)?
                    <FlatList
                    style={{width: '100%',}}
                    data={marketData.newlyListed}
                    extraData={marketData.newlyListed}
                    horizontal={true}
                    showsHorizontalScrollIndicator={false}
                    renderItem={({item}) => (

                       <TouchableOpacity

                       onPress={() => navigation.navigate('Token Page',{item: item,currency: currency})}>
                       <View style={DarkTheme.rowContainermain}>
                           <View style={{ flexDirection:'row',alignItems:'center',width:'100%'}}>

                           <Image
                                   source={{uri: item.project_info.logo_url}}
                                   style={DarkTheme.imgStylemain}
                                   resizeMode={'contain'}
                                   ></Image>
                               <Text style={((item.symbol).length > 6) ? DarkTheme.nameMainLong : DarkTheme.namemain}>{item.symbol.toUpperCase()}</Text>
                           </View>
                           <View style={{width:'100%',alignItems:'center',justifyContent:'center',flexDirection:'row'}}>
                               <Text style={DarkTheme.valuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? currency.currency_prefix + "0.0" : null}</Text>
                               <Text style={DarkTheme.decimalValuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? decimalCheck(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) : null}</Text>
                               <Text style={DarkTheme.valuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? priceFormat(parseFloat(item.usd_price)*parseFloat(currency.currency_rate),decimalCheck(parseFloat(item.usd_price)*parseFloat(currency.currency_rate))+2): currency.currency_prefix+(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)).toFixed(5)} </Text>
                           </View>
                           <View style={{flexDirection:'row',justifyContent:"space-evenly",width:'100%',alignItems:'center'}}>

                               <Text style={[(item.price_change > 0.00) ? DarkTheme.rowPercentageGain : DarkTheme.rowPercentageLoss]}>{parseFloat(item.price_change).toFixed(4)}%</Text>
                               <Image
                               source={(item.chain == 56) ? Images.bsc : (item.chain == 1) ? Images.eth : Images.poly}
                               style={DarkTheme.chainImgStylemain}
                               ></Image>
                           </View>




                       </View>
                       </TouchableOpacity>


                       ) }

                       keyExtractor={(item)=> item.token_id}
                       />
                       : <MarketsContentLoader />}

              </View>

              <View style={DarkTheme.flatlistContainermain}>
              <View style={{flexDirection:'row',alignItems:'center'}}>
                 <Image
                     source={require( './../../assets/icons/chart_up.png')}
                     style={{width: 40,
                       height: 40,
                       marginBottom:5
                     }}
                   ></Image>
                 <Text style={DarkTheme.titlemain}>Top Performers</Text>
               </View>

               {(marketData)?
               <FlatList
                       style={{width: '100%'}}
                       data={marketData.gainers}
                       extraData={marketData.gainers}
                       horizontal={true}
                       showsHorizontalScrollIndicator={false}
                       renderItem={({item}) => (

                      <TouchableOpacity

                      onPress={() => navigation.navigate('Token Page',{item: item,currency: currency})}>
                      <View style={DarkTheme.rowContainermain}>
                          <View style={{ flexDirection:'row',alignItems:'center',width:'100%'}}>

                          <Image
                                  source={{uri: item.project_info.logo_url}}
                                  style={DarkTheme.imgStylemain}
                                  resizeMode={'contain'}
                                  ></Image>
                              <Text style={((item.symbol).length > 6) ? DarkTheme.nameMainLong : DarkTheme.namemain}>{item.symbol.toUpperCase()}</Text>
                          </View>
                          <View style={{width:'100%',alignItems:'center',justifyContent:'center',flexDirection:'row'}}>
                              <Text style={DarkTheme.valuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? currency.currency_prefix + "0.0" : null}</Text>
                              <Text style={DarkTheme.decimalValuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? decimalCheck(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) : null}</Text>
                              <Text style={DarkTheme.valuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? priceFormat(parseFloat(item.usd_price)*parseFloat(currency.currency_rate),decimalCheck(parseFloat(item.usd_price)*parseFloat(currency.currency_rate))+2): currency.currency_prefix+(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)).toFixed(5)} </Text>
                          </View>
                          <View style={{flexDirection:'row',justifyContent:"space-evenly",width:'100%',alignItems:'center'}}>

                              <Text style={[(item.price_change > 0.00) ? DarkTheme.rowPercentageGain : DarkTheme.rowPercentageLoss]}>{parseFloat(item.price_change).toFixed(4)}%</Text>
                              <Image
                              source={(item.chain == 56) ? Images.bsc : (item.chain == 1) ? Images.eth : Images.poly}
                              style={DarkTheme.chainImgStylemain}
                              ></Image>
                          </View>




                      </View>
                      </TouchableOpacity>


                      ) }

                      keyExtractor={(item)=> item.token_id}
                      />
                      : <MarketsContentLoader />}

             </View>

             <View style={DarkTheme.flatlistContainermain}>
             <View style={{flexDirection:'row',alignItems:'center'}}>
                <Image
                    source={require( './../../assets/icons/chart_down.png')}
                    style={{width: 40,
                      height: 40,
                      marginBottom:5
                    }}
                  ></Image>
                <Text style={DarkTheme.titlemain}>Worst Performers</Text>
              </View>

                 {(marketData)?
                  <FlatList
                  style={{width: '100%',}}
                  data={marketData.losers}
                  extraData={marketData.losers}
                  horizontal={true}
                  showsHorizontalScrollIndicator={false}
                  renderItem={({item}) => (

                     <TouchableOpacity

                     onPress={() => navigation.navigate('Token Page',{item: item,currency: currency})}>
                     <View style={DarkTheme.rowContainermain}>
                         <View style={{ flexDirection:'row',alignItems:'center',width:'100%'}}>

                         <Image
                                 source={{uri: item.project_info.logo_url}}
                                 style={DarkTheme.imgStylemain}
                                 resizeMode={'contain'}
                                 ></Image>
                             <Text style={((item.symbol).length > 6) ? DarkTheme.nameMainLong : DarkTheme.namemain}>{item.symbol.toUpperCase()}</Text>
                         </View>
                         <View style={{width:'100%',alignItems:'center',justifyContent:'center',flexDirection:'row'}}>
                             <Text style={DarkTheme.valuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? currency.currency_prefix + "0.0" : null}</Text>
                             <Text style={DarkTheme.decimalValuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? decimalCheck(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) : null}</Text>
                             <Text style={DarkTheme.valuesmain}>{(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)) < 0.01 ? priceFormat(parseFloat(item.usd_price)*parseFloat(currency.currency_rate),decimalCheck(parseFloat(item.usd_price)*parseFloat(currency.currency_rate))+2): currency.currency_prefix+(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)).toFixed(5)} </Text>
                         </View>
                         <View style={{flexDirection:'row',justifyContent:"space-evenly",width:'100%',alignItems:'center'}}>

                             <Text style={[(item.price_change > 0.00) ? DarkTheme.rowPercentageGain : DarkTheme.rowPercentageLoss]}>{parseFloat(item.price_change).toFixed(4)}%</Text>
                             <Image
                             source={(item.chain == 56) ? Images.bsc : (item.chain == 1) ? Images.eth : Images.poly}
                             style={DarkTheme.chainImgStylemain}
                             ></Image>
                         </View>




                     </View>
                     </TouchableOpacity>


                     ) }

                     keyExtractor={(item)=> item.token_id}
                     />
                     : <MarketsContentLoader />}

            </View>




                </ScrollView>

                    }

        </View>


    )

  }

export default Markets;


