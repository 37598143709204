import React, {useState,useEffect,useRef} from 'react';
import { Modal,StyleSheet,ActivityIndicator,Image,TouchableOpacity,FlatList,Text, View,Dimensions} from 'react-native';
import { Auth} from 'aws-amplify';
import {useRoute} from '@react-navigation/native';

import {Swipeable,GestureHandlerRootView } from 'react-native-gesture-handler';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import AsyncStorage from '@react-native-async-storage/async-storage';
//import { useFonts, Poppins_500Medium } from '@expo-google-fonts/poppins';
import { useFonts, Poppins_500Medium} from '@expo-google-fonts/poppins'
import AppLoading from 'expo-app-loading';
import { LightTheme } from './portfolioLightTheme';
import Menu from '../components/menu';
import { DarkTheme } from './portfolioDarkTheme';
import * as Clipboard from 'expo-clipboard';
import PortfolioContentLoader from './portfolioContentLoader';
import * as Notifications from 'expo-notifications';

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }

function HomeScreen({ navigation,route }) {
    const initialNative = {"eth": {"balance": -5000.00, "value": -5000.00}, "bnb": {"balance": -5000.00, "value": -5000.00}, "poly": {"balance": -5000.00, "value": -5000.00}}
  const [refreshing, setRefreshing] = useState(true);
    const [isLoading,setLoading]=useState(true);
    const [edit,setEdit]=useState(false);
    const [tabular,setTabular] = useState(undefined);
    const [total,setTotal] = useState(null);
    const [native,setNative] = useState(initialNative);
    const [newToken, setNewTokens] = useState([]);
    const [dimensions, setDimensions] = useState({ window, screen });


    const [modalVisible, setModalVisible] = useState(false);
    const [currency,setCurrency] = useState({
      "currency_code": "USD",
      "currency_prefix": ".",
      "currency_rate": "1.0",
    });

    const [selected,setSelected]=useState([]);
    const [submitting,setSubmitting]=useState(false);
    const [hideValues,setHideValues] = useState(false);
    const [dropdown,setDropdown]=useState(false);



    useEffect(() => {
      //registerForPushNotificationsAsync("subscribe")
      const subscription = Dimensions.addEventListener(
        "change",
        ({ window, screen }) => {
          setDimensions({ window, screen });
          console.log(dimensions)
          
        }
      );
      getConverstionRate();
      setData();
      resetBadge();
      load();
      refresh();
       


    }, [navigation])

    

    const registerForPushNotificationsAsync = async (action) => {

      const { status: existingStatus } = await Notifications.getPermissionsAsync();
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      const token = (await Notifications.getExpoPushTokenAsync()).data;
      console.log(token);
      //this.setState({ expoPushToken: token });


    if (Platform.OS === 'android') {
      Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }
    var email = await getUser();

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    
      var sub = "new_listings"
    

    var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/subscribe/mobile?token_id=null&action="+action+"&pushToken="+token+"&email="+email+"&subscription="+sub, requestOptions);
    var d = await grab.json();
    console.log(d)

    
      
    };

    async function resetBadge() {
      await Notifications.setBadgeCountAsync(0);
    }

    async function load(){
      setData();
      setTabular([]);
      setNative(initialNative);
      setRefreshing(true);
      getNative();
      var email = await getUser();

      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/holdings?email="+email, requestOptions)
        .then(response => response.text())
        .then(result => {
          var p = JSON.parse(result);
          setTabular(p);
          setRefreshing(false);
          //console.log(p);
        })
        .catch(error => console.log('error', error));


    }


    async function load2(){
      getNative();
      var email = await getUser();

      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/holdings?email="+email, requestOptions)
        .then(response => response.text())
        .then(result => {
          var p = JSON.parse(result);
          setTabular(p);
          //console.log(p);
        })
        .catch(error => console.log('error', error));


    }

    async function refresh(){
      var email = await getUser();
      
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/update?email="+email, requestOptions)
      var values = await grab.json();
      console.log(values);
      

      newTokens();
      load2();
    }

    async function submit(){
      setSubmitting(true);
      var email = await getUser();
      //var body = await {"Command":"new-tokens","User":email};
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
     
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };
      let list =JSON.stringify(selected)
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/new-tokens/save?email="+email+"&tokens="+list, requestOptions)
      var data = await grab.json();
      setModalVisible(false);
      
    }




    async function newTokens(){
      var email = await getUser();
      //var body = await {"Command":"new-tokens","User":email};
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
     
      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/new-tokens?email="+email, requestOptions)
      var data = await grab.json();
      data = data.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
      console.log(data);
      setNewTokens(data);
      //console.log(newToken.length);
      if (data.length >0){
      setModalVisible(true);
      }
    }

    async function getConverstionRate() {
      var code = await getData();
      if (code.currency_code != 'USD') {
        var data = await {"action":"specific","code":code.currency_code};
      var raw = await JSON.stringify(data);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };
      var grab = await fetch("https://3ekla1k0v6.execute-api.us-east-1.amazonaws.com/beta/getCurrencyPrice", requestOptions)
      var newData = await grab.json();
      //console.log(newData.currency_rate);
      await storeData({"currency_code":code.currency_code,"currency_rate":newData[0].currency_rate,"currency_prefix":code.currency_prefix})
      var code = await getData();
      //console.log(code);
    }


    }

    const storeData = async (value) => {
      try {
        const jsonValue = JSON.stringify(value)
        await AsyncStorage.setItem('@currency', jsonValue)
      } catch (e) {
        // saving error
      }
    }

    const getData = async () => {
      try {
        const jsonValue = await AsyncStorage.getItem('@currency');
        return jsonValue != null ? JSON.parse(jsonValue) : {
          "currency_code": "USD",
          "currency_prefix": "$",
          "currency_rate": "1.00",
        };
      } catch(e) {
        // error reading value
      }
    }

    const setData = async () => {
      var data = await getData();
      setCurrency(data);
  }

  const decimalCheck = (num) => {
    if (num > 0){
    var zeros = 0;
    var num = parseFloat(num)
    if (num < 0.01){
    while(num < 1){
      zeros++;
      num *= 10;

    }
    return zeros-1

  } else {
    return 0
  }
  } else {return 0}

    //console.log("zeros is :"+zeros);
    //setDecimalCount(zeros+2);
  }

    async function getNative() {
      var user = await getUser();
      var data = await {"User":user};
      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

      var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };

      fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/holdings/native?email="+user, requestOptions)
        .then(response => response.text())
        .then(result => {
          let d = JSON.parse(result)
          if (d[0]){
          setNative(d[0])
          console.log(result);
        }else {
          setNative(
    {

        "eth": {
            "balance": 0,
            "value": 0
        },
        "bnb": {
            "balance": 0,
            "value": 0
        },
        "matic": {
            "balance": 0,
            "value": 0
        }
    }
)
        }
          setLoading(false);
        })
        .catch(error => console.log('error', error));

    }

  function formatPercentage(item,number) {
    number = parseFloat(number).toFixed(2);

    return number;
  }

  const priceFormat = (num,zeros) => {

    num = num.toFixed(18)
    num = num.toString().slice(zeros)
    num = num.slice(0,5)
    return num

    //console.log("zeros is :"+zeros);
    //setDecimalCount(zeros+2);
  }



  function totalPortfolio() {
    var total = 0;
    for (var x of tabular){
      total += parseFloat(x.value);
    }
    if (native != ""){
      var data = native;
      var nativeValues = data.eth.value + data.bnb.value + data.matic.value;
      total += nativeValues;
      total = total*parseFloat(currency.currency_rate);
    }
    return total.toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  }

  function ethBalance(){
    if (native != "" && native != null && typeof native !== 'undefined'){
      var data = native;

      return (data.eth.value*parseFloat(currency.currency_rate)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }

  function bnbBalance(){
    if (native != "" && native != null && typeof native !== 'undefined'){
      var data = native;
      return (data.bnb.value*parseFloat(currency.currency_rate)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    }
  }

  function polyBalance(){
    if (native != "" && native != null && typeof native !== 'undefined'){
      var data = native;
      return (data.matic.value*parseFloat(currency.currency_rate)).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }



  async function removeToken(item,contract) {
    var user = await getUser();

    var myHeaders = new Headers();
    myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };
    fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/token-visibility?visible=true&email="+user+"&tokenId="+item._id, requestOptions).then(response => response.json())
    .then((responseJson) => {});
    const fData = tabular.filter(item => item.contract !== contract);
    setTabular(fData);
  }

  const Header = () => (
      <View style={DarkTheme.portfolioHeaderBackground}>
              <View style={{paddingBottom:7,width:30}}>

              </View>
              <View>
                  <Text style={DarkTheme.headerText}>Portfolio</Text>
              </View>

              <View style={{paddingBottom:9}}>
                <TouchableOpacity style={{paddingBottom:2,width:30,alignItems:'center'}} onPress={() =>{dropdown ?setDropdown(false) :setDropdown(true)}}>
                            <Text style={DarkTheme.values}><FontAwesome5 name={'caret-down'} style={{fontSize:25,color:'#fff'}} /></Text>
                </TouchableOpacity>
              </View>

          </View>
    )

    



  const ImgBsc = () => (
    <View style = {{justifyContent:'center', alignItems:'center', width:'18%'}}>

        <Image
            source={{uri: "https://token-images.s3.amazonaws.com/bnb-gray.png"}}
            style={DarkTheme.imgStyle}
            resizeMode={'contain'}
            ></Image>
    </View>
  )

  const ImgEth = () => (
    <View style = {{justifyContent:'center', alignItems:'center', width:'18%'}}>

    <Image
          source={{uri: "https://token-images.s3.amazonaws.com/eth-gray.png"}}
          style={DarkTheme.imgStyle}
          resizeMode={'contain'}
          ></Image>
    </View>
  )

  const ImgPoly = () => (
    <View style = {{justifyContent:'center', alignItems:'center', width:'18%'}}>

    <Image
          source={{uri: "https://token-images.s3.amazonaws.com/poly-gray.png"}}
          style={DarkTheme.imgStyle}
          resizeMode={'contain'}
          ></Image>
    </View>
  )

  let [fontsLoaded] = useFonts({
    Poppins_500Medium,
  });

  function formatWallet(wallet) {
    if (wallet != null){
    wallet = wallet.slice(0,5)+'...'+wallet.slice(38);


    return wallet;
    }
  }

  if (!fontsLoaded) {
    return <AppLoading />;
  }


    return (

      <View style={DarkTheme.container}>
        <Header />
        

        {dropdown ?
          <View style={DarkTheme.dropdownContainer}>
            <View style={DarkTheme.dropdownDataContainer}>
              <TouchableOpacity style={{justifyContent:'center',alignItems:'center',}} onPress={async() => {setDropdown(false); navigation.navigate('Add Token')}}>
                <Text style={DarkTheme.values}>Add Staked Token</Text>
              </TouchableOpacity>
            </View>
          </View> : null}

          <Menu props={{"navigation": navigation}}/>

        <View style={DarkTheme.portfolio}>

          <View style={{flexDirection:'row',width:'100%',justifyContent:'center'}}>
              <View style={dimensions.window.width > 700 ? DarkTheme.pContainerLarge : DarkTheme.pContainerSmall}>
                  <Text style={DarkTheme.portfolioText}>Portfolio Value</Text>
                  <Text style={DarkTheme.portfolioTotal}>{currency.currency_prefix} {(native.eth.balance == -5000 || hideValues) ? '...' : totalPortfolio()}</Text>
              </View>
              <View style={dimensions.window.width > 700 ? DarkTheme.iContainerLarge : DarkTheme.iContainerSmall}>
                <TouchableOpacity style={{paddingTop:17,width:'100%'}} onPress={()=> {!edit ?setEdit(true):setEdit(false);}}>
                {edit ? <FontAwesome5 name={'times'} style={{fontSize:dimensions.window.width > 700 ? 25 : 20,color:'#fff'}}/> : <FontAwesome5 name={'edit'} style={{fontSize:dimensions.window.width > 700 ? 25 : 20,color:'#fff'}}/>}
                </TouchableOpacity>
              </View>
              <View style={dimensions.window.width > 700 ? DarkTheme.iContainerLarge : DarkTheme.iContainerSmall}>
                <TouchableOpacity style={{paddingTop:17,width:'100%'}} onPress={()=> {setRefreshing(true); load()}}>
                <FontAwesome5 name={'sync'} style={{fontSize:dimensions.window.width > 700 ? 25 : 20,color:'#fff'}}/>
                </TouchableOpacity>
              </View>
              <View style={dimensions.window.width > 700 ? DarkTheme.iContainerLarge : DarkTheme.iContainerSmall}>
                <TouchableOpacity style={{paddingTop:17,width:'100%'}} onPress={()=> {!hideValues?setHideValues(true):setHideValues(false)}}>
                {hideValues ? <FontAwesome5 name={'eye-slash'} style={{fontSize:dimensions.window.width > 700 ? 25 : 20,color:'#fff'}}/> : <FontAwesome5 name={'eye'} style={{fontSize:dimensions.window.width > 700 ? 25 : 20,fontSize:25,color:'#fff'}}/>}
                </TouchableOpacity>
              </View>
              
          </View>


          <View style={{flexDirection:'row',marginTop:10,alignItems:'center',justifyContent:'space-evenly',width:'100%'}}>
            <View style={DarkTheme.nativeImageContainer}>

                  <Image
                    source={require( './../img/logos/eth.png')}
                    style={{width: 40,
                      height: 40,
                      marginLeft:5,
                      marginTop:5,
                    }}
                  ></Image>

                <View style={DarkTheme.nativeSymbolContainer}>
                  <Text style={DarkTheme.nativeSymbols}>ETH</Text>
                  <Text style={DarkTheme.nativeValues}>{currency.currency_prefix}{(native.eth.balance == -5000 || hideValues) ? '...' : ethBalance()}</Text>
                  <Text style={DarkTheme.nativeValuesB}>{(native.eth.balance == -5000 || hideValues) ? '...' : (native.eth.balance >= 1) ? native.eth.balance.toFixed(2) : native.eth.balance.toFixed(3)}</Text>


                </View>




            </View>
            <View style={DarkTheme.nativeImageContainer}>

                <Image
                  source={require( './../img/logos/bnb.png')}
                  style={{width: 37,
                    height: 37,
                    marginLeft:5,
                    marginTop:5,
                  }}
                ></Image>

                <View style={DarkTheme.nativeSymbolContainer}>
                  <Text style={DarkTheme.nativeSymbols}>BNB</Text>
                  <Text style={DarkTheme.nativeValues}>{currency.currency_prefix}{(native.eth.balance == -5000 || hideValues) ? '...' : bnbBalance()}</Text>
                  <Text style={DarkTheme.nativeValuesB}>{(native.eth.balance == -5000 || hideValues) ? '...' : (native.bnb.balance >= 1) ? native.bnb.balance.toFixed(2) : native.bnb.balance.toFixed(3)}</Text>

                </View>




            </View>
            <View style={DarkTheme.nativeImageContainer}>

              <Image
                  source={require( './../img/logos/poly.png')}
                  style={{width: 40,
                    height: 40,
                    marginLeft:5,
                    marginTop:5,
                  }}
                ></Image>

                <View style={DarkTheme.nativeSymbolContainer}>
                  <Text style={DarkTheme.nativeSymbols}>MATIC</Text>
                  <Text style={DarkTheme.nativeValues}>{currency.currency_prefix}{(native.eth.balance == -5000 || hideValues) ? '...' : polyBalance()}</Text>
                  <Text style={DarkTheme.nativeValuesB}>{(native.eth.balance == -5000 || hideValues) ? '...' : (native.matic.balance >= 1) ? native.matic.balance.toFixed(2) : native.matic.balance.toFixed(3)}</Text>

                </View>




            </View>

          </View>

        </View>

        <Modal
        animationType="none"
        transparent={true}
        visible={modalVisible}
      >
        <View style={DarkTheme.modalBackground}>
        <View style={DarkTheme.modalContainer}>
                  <Text style={DarkTheme.modalHeader}>New {(newToken.length > 1 ? 'tokens' : 'token')} detected!</Text>
        <View style={{width:'100%',height:30,flexDirection:'row',alignItems:'center',justifyContent:'center',}}>
            <Text style={DarkTheme.modalRowLabels}>Select valid tokens and click done.</Text>
        </View>
        <FlatList
          style={{width: '100%'}}
          data={newToken}
          extraData={newToken}
          renderItem={({item}) => (

              <TouchableOpacity onPress={async()=> {
              if(selected.includes(item.token_id)){
                let currList = await [...selected];
                //console.log(currList);
                var index = await currList.indexOf(item.token_id)

                await currList.splice(index,1);
                //console.log(currList);
                await setSelected(currList);
              }else{
                //console.log('Adding to List');
                await setSelected([...selected,item.token_id]);
              }





          }}>
            <View style={(selected.includes(item.token_id)) ? DarkTheme.modalRowContainerSelected : DarkTheme.modalRow}>
            {(item.chain == 56) ? <ImgBsc /> : (item.chain == 1) ? <ImgEth /> : (item.chain == 137) ? <ImgPoly /> : null}
              <View style={DarkTheme.modalRowSection}>
                <Text style={DarkTheme.modalRowLabels}>Name</Text>
                <Text style={DarkTheme.modalRowName}>{item.name}</Text>

              </View>
            <View style={DarkTheme.modalRowSection}>
            <TouchableOpacity style={{justifyContent:'center', alignItems:'center',}}onPress={() => {Clipboard.setString(item.contract)}}>
                <Text style={DarkTheme.modalRowLabels}>Contract</Text>
                <Text style={DarkTheme.modalRowName}>{formatWallet(item.contract)} <FontAwesome5 name={'copy'} style={{fontSize:14}}/></Text>

              </TouchableOpacity>

            </View>

            </View>
            </TouchableOpacity>

          )}
          keyExtractor={(item)=> item.token_id+item._id}
        />
        <View style={DarkTheme.modalCloseContainer}>
        <TouchableOpacity style= {DarkTheme.modalCloseButton} onPress={() => {submit();}}>
              {submitting ? <ActivityIndicator size="small" color="#fff" />:null}
              <Text style={DarkTheme.modalCloseButtonText}>Done</Text>
        </TouchableOpacity>
        </View>
        </View>

        </View>


        </Modal>
        {(native.eth.balance == -5000)?<PortfolioContentLoader />:

          <FlatList
          style={{width: '100%'}}
          data={tabular}
          extraData={tabular}

          onRefresh={() => {load();}}
          refreshing={refreshing}
          renderItem={({item}) => (
            



            <TouchableOpacity onPress={() => navigation.navigate('Token Details',{item: item,currency: currency,hideValues: hideValues})}>

            <View style={DarkTheme.rowContainer}>
            <View style = {{width:'20%',alignItems:'center',justifyContent:'center'}}>
                <Image
                source={{uri: item.url}}
                style={DarkTheme.imgStyle}
                resizeMode={'contain'}
                ></Image>
            </View>
                {item.price > 0.001 ?
                <View style={{justifyContent:'center', width:'27%',}}>
                    <Text style={DarkTheme.rowSymbol}>{item.symbol.slice(0,10).toUpperCase()}</Text>
                    <Text style={DarkTheme.rowPrice}>{(item.price >= 1) ? (parseFloat(item.price)*parseFloat(currency.currency_rate)).toFixed(5).toString().slice(0,11) : (parseFloat(item.price)*parseFloat(currency.currency_rate)).toFixed(20).toString().slice(0,14)}</Text>
                </View>
                :
                <View style={{justifyContent:'center', width:'27%'}}>
                    <Text style={DarkTheme.rowSymbol}>{item.symbol.slice(0,10).toUpperCase()}</Text>
                    <View style={{width:'100%',flexDirection:'row'}}>
                        <Text style={DarkTheme.rowPrice}>{(parseFloat(item.price)*parseFloat(currency.currency_rate)) < 0.01 ? "0.0" : null}</Text>
                        <Text style={[DarkTheme.rowPrice,{paddingTop:5,fontSize:8,marginHorizontal:1}]}>{(parseFloat(item.price)*parseFloat(currency.currency_rate)) < 0.01 ? decimalCheck(parseFloat(item.price)*parseFloat(currency.currency_rate)) : null}</Text>
                        <Text style={DarkTheme.rowPrice}>{(parseFloat(item.price)*parseFloat(currency.currency_rate)) < 0.01 ? priceFormat(parseFloat(item.price)*parseFloat(currency.currency_rate),decimalCheck(parseFloat(item.price)*parseFloat(currency.currency_rate))+2): currency.currency_prefix+(parseFloat(item.usd_price)*parseFloat(currency.currency_rate)).toFixed(5)} </Text>
                    </View>
                </View>
            }
            
            
            <View style={{flexDirection:'column', width:'30%',alignItems:'center',justifyContent:'center'}}>
                <Text style={DarkTheme.rowValue}>{currency.currency_prefix} {hideValues ? '...' :(parseFloat(item.value)*parseFloat(currency.currency_rate)).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Text>
            </View>
            <View style={{flexDirection:'column', width:'23%',alignItems:'center',justifyContent:'center'}}>

                <Text style={[(item.twentyFourHour > 0.00) ? DarkTheme.rowPercentageGain : DarkTheme.rowPercentageLoss]}> {formatPercentage(item,item.twentyFourHour)}%</Text>

            </View>
            {edit ? <><TouchableOpacity style={DarkTheme.swipeStyle} onPress={()=> {removeToken(item,item.contract)}}>
                     <FontAwesome5 name={'eye-slash'} style={DarkTheme.hideIcon}/>
            </TouchableOpacity></> : null}
            </View>
            </TouchableOpacity>
            
          )}
          keyExtractor={(item)=> item._id}
        />
          }
      </View>

    );}


  export default HomeScreen;
