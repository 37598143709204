import React, {useState,useEffect} from 'react';
import { Animated,Image,TouchableWithoutFeedback,TouchableOpacity,FlatList,StyleSheet,Text,TextInput, View, Button,ActivityIndicator, Alert } from 'react-native';
import Amplify, { Auth, withSSRContext } from 'aws-amplify';
import {Swipeable,GestureHandlerRootView } from 'react-native-gesture-handler';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import * as Clipboard from 'expo-clipboard';
import Menu from '../components/menu';
import { ScanWalletStyles } from './walletDarkTheme';



const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }


function ScanWallet({ navigation,route }) {
    const [newWallet,setWallet] = useState('wallet');
    const [tabular,setTabular] = useState([{"symbol": "loading"}]);
    const [isLoading,setLoading]=useState(true);
    const [submitting,setSubmitting]=useState(false);
    const [tokenList,setTokenList]=useState([]);
    const [selected,setSelected]=useState([]);
    const [native,setNative] = useState('');
    const [newToken, setNewTokens] = useState([]);

    function formatWallet(wallet) {
      if (wallet != null){
      wallet = wallet.slice(0,5)+'...'+wallet.slice(38);


      return wallet;
      }
    }

    async function submit(){
      setSubmitting(true);
      var answer = await save()
      setSubmitting(false);
      navigation.navigate('Your Wallets');

    }

    async function refresh(){
        var email = await getUser();
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
        //console.log(route.params.wallet.wallet_added)
        var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/setup?email="+email+"&wallet="+route.params.wallet.wallet_added, requestOptions)
        var values = await grab.json();


        //console.log(values);
        setTabular(values);
        setLoading(false);

      }


      async function save() {
        var user = await getUser();
        //var Data = {"chain":item.chain,"contract":item.contract, "user": user, "action": "save"};
        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };
        let list =JSON.stringify(selected)
        let req = "https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/setup/save?email="+user+"&wallet="+route.params.wallet.wallet_added+"&tokens="+list;
        console.log(req)
        var grab = await fetch(req, requestOptions)

        var data = await grab.json();
        console.log(data)
        return data
      }

      useEffect(() => {
        () =>
        navigation.addListener('beforeRemove', (e) => {Alert.alert('You Sure')});
        refresh();

      }, [route.params.wallet])

      const ImgBsc = () => (
        <View style = {{justifyContent:'center', alignItems:'center', width:'18%'}}>

            <Image
                source={{uri: "https://token-images.s3.amazonaws.com/bnb-gray.png"}}
                style={ScanWalletStyles.imgStyle}
                resizeMode={'contain'}
                ></Image>
        </View>
      )

      const ImgEth = () => (
        <View style = {{justifyContent:'center', alignItems:'center', width:'18%'}}>

        <Image
              source={{uri: "https://token-images.s3.amazonaws.com/eth-gray.png"}}
              style={ScanWalletStyles.imgStyle}
              resizeMode={'contain'}
              ></Image>
        </View>
      )

      const ImgPoly = () => (
        <View style = {{justifyContent:'center', alignItems:'center', width:'18%'}}>

        <Image
              source={{uri: "https://token-images.s3.amazonaws.com/poly-gray.png"}}
              style={ScanWalletStyles.imgStyle}
              resizeMode={'contain'}
              ></Image>
        </View>
      )


      const Header = () => (
        <View style={ScanWalletStyles.headerBackground}>
                <Text style={ScanWalletStyles.headerText}>Wallet Setup</Text>
            </View>
      )




      if (isLoading == true){
        return (
          <View style={ScanWalletStyles.container}>
            <ActivityIndicator size='large' color={(opacity = 2) => `rgba(100, 255, 255, ${opacity})`} />

            </View>
        )
      }else{
        return(
        <View style={ScanWalletStyles.main}>
          <Header />
          <Menu props={{"navigation": navigation}}/>
      <View style={ScanWalletStyles.maincontainer}>
        <View style={{alignSelf:'center',justifyContent:'center'}}>
          <Text style={ScanWalletStyles.mainwallet}>Wallet: {formatWallet(route.params.wallet.wallet_added)}</Text>
          <View style={{width:'100%',flexDirection:'row',justifyContent:'space-evenly'}}>
            <View>
                <Text style={ScanWalletStyles.mainlabel}>Tokens</Text>
                <Text style={ScanWalletStyles.maindata}>{route.params.wallet.tokens}</Text>
            </View>
            <View>
                <Text style={ScanWalletStyles.mainlabel}>Transactions</Text>
                <Text style={ScanWalletStyles.maindata}>{route.params.wallet.transactions}</Text>
            </View>
            <View>
                <Text style={ScanWalletStyles.mainlabel}>Transfers</Text>
                <Text style={ScanWalletStyles.maindata}>{route.params.wallet.transfers}</Text>
            </View>
          </View>
      </View>
        </View>
        <View style={{paddingHorizontal:10}}>
          <Text style={{color:'#fff',alignSelf:'center',fontFamily:'Poppins_500Medium',fontSize:18,paddingTop:10,paddingHorizontal:10}}>Select your tokens below!</Text>
          <Text style={ScanWalletStyles.warning}>Select each token you wish to track in your portfolio. The rest will be ignored. Don't worry, you can always unhide tokens later.</Text>
        </View>
            <FlatList
            style={{width: '100%'}}
            data={tabular}
            extraData={selected}
            renderItem={({item}) => (


            <TouchableOpacity onPress={async()=> {
              if(selected.includes(item._id)){
                let currList = await [...selected];
                //console.log(currList);
                var index = await currList.indexOf(item._id)

                await currList.splice(index,1);
                //console.log(currList);
                await setSelected(currList);
              }else{
                //console.log('Adding to List');
                await setSelected([...selected,item._id]);
              }
              //console.log(selected);





          }}>
             <View style={(selected.includes(item._id))? ScanWalletStyles.rowContainerSelected:ScanWalletStyles.rowContainer}>

             <View style = {{justifyContent:'center', alignItems:'center', width:'18%'}}>

                 <Image
                     source={{uri: item.url}}
                     style={ScanWalletStyles.imgStyle}
                     resizeMode={'contain'}
                     ></Image>
             </View>
              <View style = {{justifyContent:'center', alignItems:'center', width:'50%'}}>
                  <Text style={(selected.includes(item._id))? ScanWalletStyles.selectedlabels:ScanWalletStyles.labels}>Token Name</Text>
                  <Text style={ScanWalletStyles.wallets}>{item.name}</Text>

              </View>
            <View style = {{justifyContent:'center', alignItems:'center'}}>
              <TouchableOpacity style={{justifyContent:'center', alignItems:'center',}}onPress={() => {Clipboard.setString(item.contract)}}>
                <Text style={(selected.includes(item._id))? ScanWalletStyles.selectedlabels:ScanWalletStyles.labels}>Contracts</Text>
                <Text style={ScanWalletStyles.walletName}>{formatWallet(item.contract)} <FontAwesome5 name={'copy'} style={{fontSize:14}}/></Text>

              </TouchableOpacity>

            </View>



            </View>
            </TouchableOpacity>

            )}
            keyExtractor={(item)=> item._id}
            />

            <View style={{flexDirection:'row', justifyContent:'center',alignItems:'center'}}>
              <TouchableOpacity style={ScanWalletStyles.saveButton} onPress={()=>submit()}>
                {submitting ? <ActivityIndicator size="small" color="#fff" />:null}
                <Text style={ScanWalletStyles.saveButtonText}>{submitting ? '    Saving': "Save"}</Text>
              </TouchableOpacity>
            </View>

            </View>
        )
      }
  }

export default ScanWallet;


