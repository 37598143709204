import React, {useState,useEffect} from 'react';
import { Image,ActivityIndicator, TouchableOpacity,FlatList,StyleSheet,Text,TextInput, View, Button } from 'react-native';
import Amplify, { Auth, withSSRContext } from 'aws-amplify';
import { useFonts, Poppins_500Medium } from '@expo-google-fonts/poppins';
import AppLoading from 'expo-app-loading';
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import { LightTheme } from './walletLightTheme';
import { DarkTheme } from './walletDarkTheme';
import Menu from '../components/menu';



const getUser = async() => {
    var userData = await Auth.currentAuthenticatedUser();
    var email = userData.attributes.email;
    return email;
  }

const Invalid = () =>
  (
      <View style={{width:'100%'}}>
          <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  Invalid wallet address</Text>

      </View>

  )

const WalletAlreadyExists = () =>
  (
      <View style={{width:'100%'}}>
          <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  Wallet already exists in your account</Text>

      </View>

  )

const WalletIsContract = () =>
  (
      <View style={{width:'100%'}}>
          <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  Provided address is a smart contract</Text>

      </View>

  )
  const NameValidation = () =>
  (
      <View style={{width:'100%'}}>
          <Text style={DarkTheme.invalid}><FontAwesome5 name={'exclamation-triangle'} style={{fontSize:15}}/>  Give your wallet a nick name</Text>

      </View>

  )


function AddWallet({ navigation,route }) {
    const [newWallet,setWallet] = useState('wallet');
    const [name,setName] = useState('name');
    const [walletExists,setWalletExists] = useState(false);
    const [isContract,setIsContract] = useState(false);
    const [nameValidation,setNameValidation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [gatheringTokens, setGatheringTokens] = useState(false);
    const [gatheringTransactions, setGatheringTransactions] = useState(false);





    async function save(){
        
        setIsSaving(true)
        if (name == 'name' || name.length <= 0){
            setNameValidation(true);
            setIsLoading(false);
            return
        }
        console.log(newWallet,name);

        await new Promise(resolve => setTimeout(resolve, 2000));
        setIsLoading(true)

      var email = await getUser();

      var myHeaders = new Headers();
      myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow'
      };
      var grab = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/add?email="+email+"&wallet="+newWallet.toLowerCase()+"&name="+name, requestOptions);
      var data = await grab.json();
      console.log(data)
      if (data.results == true) {
        setGatheringTokens(true)
        let myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

        let requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };
        console.log('trying get transactions')
        var transDetails = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/get-transactions?wallet="+newWallet.toLowerCase(), requestOptions);
        var transdata = await transDetails.json();

        myHeaders = new Headers();
        myHeaders.append("x-api-key", "sHx2HJ9RPpaHLV7Y2oxS01wKgIceI8Oy5KesplA7");

        requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
        };
        console.log('trying get transactions')
        var streamDetails = await fetch("https://ppiku7z57a.execute-api.us-east-2.amazonaws.com/production/account/wallets/add-to-stream?wallet="+newWallet.toLowerCase(), requestOptions);
        var streamdata = await streamDetails.json();
        console.log(streamdata);
        setGatheringTransactions(true);



        var walletInfo = {
          wallet_added: newWallet.toLowerCase(),
          tokens: data.tokens,
          transfers: transdata.transfersAdded,
          transactions: transdata.transactionsAdded
        }
        
        navigation.navigate('Wallet Setup',{wallet: walletInfo});
      } else if (data.reason == 'Wallet already exists in this account.'){
          setWalletExists(true);
          setIsSaving(false);
          setIsLoading(false);
      } else if (data.reason.startsWith("Provided address is a")){
          setIsContract(true);
          setIsSaving(false);
          setIsLoading(false);
      }
      //setIsLoading(false);
    }

    let [fontsLoaded] = useFonts({
        Poppins_500Medium,
      });

      if (!fontsLoaded) {
        
        return <AppLoading />;
      }


      const Header = () => (
            <View style={DarkTheme.headerBackgroundaddWallet}>
              <TouchableOpacity style={{position:'absolute',paddingRight:300,paddingBottom:8,justifyContent:'center',alignItems:'center'}} onPress={() =>navigation.goBack()}>
                            <Text style={DarkTheme.values}><FontAwesome5 name={'angle-double-left'} style={{fontSize:25,color:'#fff'}} /></Text>
                </TouchableOpacity>
                <Text style={DarkTheme.headerTextaddWallet}>Add Wallet</Text>
            </View>
      )

      const LoadingScreen = () => (
        <View style = {{justifyContent:'center', alignItems:'center', width:'80%'}}>

            <Image
                source={{uri:"https://d7dqa0xvlu6m4.cloudfront.net/blockLoader.gif"}}
                style={{width: 400,
                  height: 400,
                  marginBottom:-50


                }}
                resizeMode={'contain'}
                ></Image>
                <View style={DarkTheme.stepColumn}>
                    <FontAwesome5 name={'check-square'} style={DarkTheme.checksComplete} />
                     <Text style={DarkTheme.stepsComplete}>   Saving Your Wallet</Text>
                </View>
                <View style={DarkTheme.stepColumn}>
                    <FontAwesome5 name={gatheringTokens ? 'check-square': 'square'} style={gatheringTokens ? DarkTheme.checksComplete : DarkTheme.checks} />
                     <Text style={gatheringTokens ? DarkTheme.stepsComplete : DarkTheme.steps}>   Gathering Token Balances</Text>
                </View>
                <View style={DarkTheme.stepColumn}>
                    <FontAwesome5 name={gatheringTransactions ? 'check-square': 'square'} style={gatheringTransactions ? DarkTheme.checksComplete : DarkTheme.checks} />
                     <Text style={gatheringTransactions ? DarkTheme.stepsComplete : DarkTheme.steps}>   Gathering Transaction History</Text>
                </View>
                
        </View>
      )




    return (
        <View style={DarkTheme.containeraddWallet}>
            <Header />
            <Menu props={{"navigation": navigation}}/>
            {isLoading ? <LoadingScreen /> :
            <View style={DarkTheme.form}>
                    

                    <Text style={DarkTheme.t}>Enter a valid Ethereum, Binance Smart Chain, or Polygon wallet address.</Text>
                    <Text style={DarkTheme.label}> Wallet Address </Text>
                    <TextInput
                    style={DarkTheme.textInput}
                    value={null}
                    placeholder="Wallet Address"
                    onChangeText={newWallet => setWallet(newWallet)} />
                    {(newWallet != 'wallet' && newWallet.length != 0 && newWallet.startsWith("0x") == false || newWallet.length != 42 && newWallet != 'wallet' && newWallet.length != 0) ? <Invalid /> : null}
                    {(walletExists == true) ? <WalletAlreadyExists /> : null}
                    {(isContract == true) ? <WalletIsContract /> : null}

                    <Text style={DarkTheme.label}> Nickname </Text>
                    <TextInput
                    style={DarkTheme.textInput}
                    placeholder="MetaMask"
                    onChangeText={name => setName(name)}
                    />
                    {(nameValidation == true) ? <NameValidation /> : null}
                    <TouchableOpacity
                        style={DarkTheme.button}
                        onPress={save}
                        disabled={(newWallet != 'wallet' && newWallet.length != 0 && newWallet.startsWith("0x") == false || newWallet.length != 42 && newWallet != 'wallet' && newWallet.length != 0) ? true : false}
                        >
                        {isSaving && <ActivityIndicator size="small" color="#fff" />}
                        <Text style={DarkTheme.buttonText}>Save Wallet</Text>
                    </TouchableOpacity>
            

            </View>
            }

        </View>

    )
  }

export default AddWallet;
